import BaseAPIClient from '@/services/baseClient';
import { cancelAxiosRequest } from '../helpers/axiosAbort';
import { SOLUTIONS } from '@/helpers/dataLabels';
import prettyBytes from 'pretty-bytes';



export class BatchClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/batch/'
      : process.env.VUE_APP_BATCH_API_URI;
  }

  async getBatches(tid) {
    return this.instance.get(`/batches/${tid}`);
  }

  async getBatch({ id }) {
    return this.instance.get(`/batch/${id}`);
  }

  async newBatch(batch) {
    return this.instance.post('/batches', batch);
  }

  async updateBatch(update) {
    const { id } = update;
    return this.instance.put(`/batches/${id}`, update);
  }

  async deleteBatch({ _id }) {
    return this.instance.delete(`/batches/${_id}`);
  }

  async runBatch({ id, type, params }) {
    switch (type) {
      case 'AML': return this.runAmlBatch({ id });
      case 'NAMES_COMPARE': return this.runNamesComapreBatch({ id });
      case 'PI_A': return this.run314aBatch({ id, resultFormat: params });
      case 'WIRE': return this.runWireBatch({ id, resultFormat: params });
      case 'VESSEL': return this.runVesselAmlBatch({ id, resultFormat: params });
      default: throw new Error('Invalid batch request type');
    }
  }

  async runAmlBatch({ id }) {
    return await this.instance.post('/batches/execute', { _id:id });
  }

  async runVesselAmlBatch({ id, resultFormat }) {
    return await this.instance.post(`/batches/${id}/vessels`, { resultFormat });
  }
  
  async runNamesComapreBatch({ id }) {
    return this.instance.post(`/batches/${id}/names`);
  }

  async run314aBatch({ id, resultFormat }) {
    return this.instance.post(`/batches/${id}/314a`, { resultFormat });
  }

  async runWireBatch({ id, resultFormat }) {
    return this.instance.post(`/batches/${id}/wire`, { resultFormat });
  }

  async getFiles({ batchType, type, tenantId }) {
    return this.instance.get(`/files/${type}/${tenantId}/${batchType}`);
  }

  async getFile({ id }) {
    return this.instance.get(`/files/${id}`);
  }

  async newFile({ file, description, batchType }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('filesize', prettyBytes(file.size));
    formData.append('batchType', batchType);
    formData.append('type', 'input');
    
    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async uploadAchFile({ file }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', 'ACH file');
    formData.append('filesize', prettyBytes(file.size));
    formData.append('batchType', SOLUTIONS.ach);
    formData.append('type', SOLUTIONS.ach);
    
    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async updateFile(update) {
    const { _id: id } = update;
    return this.instance.put(`/files/:${id}`, update);
  }

  async deleteFile({ _id }) {
    return this.instance.delete(`/files/${_id}`);
  }

  async downloadResult({ id }) {
    return this.instance.get(`/results/${id}/payload`, { responseType: 'blob' });
  }

  async downloadFile({ id, type, tenantId }) {
    // file/:type/:tenantId/:_id
    return this.instance.get(`/file/${type}/${tenantId}/${id}/payload`, { responseType: 'blob',   cancelToken: cancelAxiosRequest.token  });
  }

  // data sources files
  async uploadDataSource({ name, file }) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', name);
    formData.append('filesize', prettyBytes(file.size));
    formData.append('type', 'data-source');

    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // TODO: add support in batch
  async getDataSources({ tenantId }) {
    return this.instance.get(`/data-source/files?metadata.user.tenantId=${tenantId}`);
  }

  async downloadDataSources({ id }) {
    return this.instance.get(`/data-source/file/${id}/payload`);
  }

  async updateDataSources({ id, name, file }) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', name);
    formData.append('filesize', prettyBytes(file.size));
    formData.append('type', 'data-source');

    return this.instance.post(`files/data-source/update/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async deleteDataSources({ id, name, tenantId }) {
    return this.instance.delete(`/data-source/files/${id}?name=${name}&tenantId=${tenantId}`);
  }

  async uploadCustomersList({ name, file }) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', name);
    formData.append('filesize', prettyBytes(file.size));
    formData.append('type', 'customers');

    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async getCustomerLists({ tenantId }) {
    return this.instance.get(`/customers/files?metadata.user.tenantId=${tenantId}`);
  }

  async downloadCUstomersList({ id }) {
    return this.instance.get(`/customers/files/${id}/payload`);
  }

  async deleteCustomersList({ id, name, tenantId }) {
    return this.instance.delete(`/customers/files/${id}?name=${name}&tenantId=${tenantId}`);
  }

  // PKYC actions
  async savePkycFile(file, description) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('filesize', prettyBytes(file.size));
    formData.append('type', 'pkyc');

    return this.instance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async getPkycFiles(tenantId) {
    return this.instance.get(`/files/pkyc/${tenantId}`);
  }

  async downloadPkycFile(id) {
    return this.instance.get(`/files/pkyc/${id}/payload`);
  }

  async deletePkycFile(id) {
    return this.instance.delete(`/files/pkyc/${id}`);
  }

  async createImmediatePkycBatch(tenantId) {
    return this.instance.post('/admin/pkyc', {tenantId});
  }
}

export default new BatchClient();
